import * as React from 'react'
import { forwardRef, useEffect, useState } from 'react'
import ClickToEdit from 'react-click-to-edit'

export const PrintContent = React.forwardRef<HTMLDivElement, any>(
  (props, ref) => {
    const { user, setEditMode } = props
    const { fullName, pop, specialty, qrLabel } = user

    const renderField = (value, className = '') => (
      <ClickToEdit
        wrapperClass={`line ${className}`}
        initialValue={value}
        startEditing={() => {
          setEditMode(true)
        }}
        endEditing={() => {
          setEditMode(false)
        }}
      />
    )

    return (
      <div ref={ref}>
        <style type="text/css" media="print">
          {`
            @page { size: 4in 6in;
              margin: 3.4in 1.5cm 0cm 1.5cm;
            }
            .line {
              display: block !important;
              text-align: center;
              line-height: 1.3;
              color: black;
            }
            .fullName {
              font-family: "Arial Black", "Arial", serif;
              font-size: 20pt;
              font-weight: bolder;
              margin-bottom: 5pt;
            }
            .pop {
              font-family: "Arial Bold", "Arial", serif;
              font-size: 16pt;
              font-weight: bold;
            }
            .CTE--wrapper:after {
              content: '' !important;
            }
            #qrLabel {
              display: block;
              margin: auto;
            }
          `}
        </style>
        {/* <div className="imageGap"></div> */}
        {renderField(fullName, 'fullName')}
        {renderField(pop, 'pop')}
        {/* <img id="qrLabel" src={qrLabel} /> */}
      </div>
    )
  }
)

// const PrintContent = forwardRef(PrintContentRef)
export default PrintContent

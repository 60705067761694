import * as React from 'react'

const AccessRight = ({ user }) => {
  const displayAttendeeType = () => {
    if (user.attendeeType === 'Staff of Maybank') {
      return (
        <div className={`d-inline-block status_tag gray`}>
          {user.attendeeType}
        </div>
      )
    } else if (user.attendeeType === 'Institutional Clients') {
      return (
        <div className={`d-inline-block status_tag orange`}>
          {user.attendeeType}
        </div>
      )
    } else if (user.attendeeType === 'Prime Brokerage Clients') {
      return (
        <div className={`d-inline-block status_tag hidden`}>
          {user.attendeeType}
        </div>
      )
    } else {
      return <div className={`fw-bold font-lg`}>{user.attendeeType}</div>
    }
  }

  return (
    <>
      <div className="mt-3">User Details:</div>
      <div className="card mt-2 p-4 w-100">
        <div className="mb-3">
          <small className="d-block mb-1 text-muted">CAN PRINT BADGE?</small>
          {user.canPrint ? (
            <div className={`d-inline-block status_tag approved`}>YES</div>
          ) : (
            <div className={`d-inline-block status_tag rejected`}>
              No. Already Printed
            </div>
          )}
        </div>
        <div className="mb-3">
          <small className="d-block mb-1 text-muted">Registration Role</small>
          {user.registeredFor === 'InstiClient' ? (
            <div className={`d-inline-block status_tag orange`}>
              {user.registeredFor}
            </div>
          ) : (
            <div className={`fw-bold font-lg`}>{user.registeredFor}</div>
          )}
        </div>
        <div className="mb-3">
          <small className="d-block mb-1 text-muted">Attendee Type</small>
          {displayAttendeeType()}
        </div>
      </div>
      <a
        className="mt-2 text-center d-block"
        href={`/admin/users/${user.id}`}
        target="_blank"
      >
        <span>View User Profile</span>
        <i className="fas fa-arrow-right ms-2"></i>
      </a>
    </>
  )
}

export default AccessRight

import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Booth } from './Booth'

import PropTypes from 'prop-types'

const Drag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grip-vertical" viewBox="0 0 16 16">
    <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
  </svg>
)

const BoothTitleWithDragIcon = ({ title }) => (
  <span style={{ display: 'flex' }}>
    <Drag />
    <span>{ title }</span>
  </span>
)

function BoothOrder({ booths: oriBooths }) {
  const [booths, setBooths] = useState([...oriBooths])

  const moveBooth = useCallback((dragIndex, hoverIndex) => {
    const newBooths = [...booths]
    const dragBooth = newBooths[dragIndex];

    newBooths.splice(dragIndex, 1)
    newBooths.splice(hoverIndex, 0, dragBooth) 

    setBooths(newBooths)
  }, [booths]);


  const renderBooth = (booth, index) => {
    return (
      <div key={booth.id}>
        <Booth
          index={index}
          id={booth.id}
          title={<BoothTitleWithDragIcon title={booth.title} />}
          moveBooth={moveBooth}
        />
        <div style={{ display: 'none' }}>
          <input readOnly type='text' name='exhibition_hall[booths][][id]' value={booth.id} /> 
          <input readOnly type='text' name='exhibition_hall[booths][][order]' value={index} /> 
        </div>
      </div>
    );
  };

  if (booths.length === 0) return 'No booths found in this hall.'

  return (
    <>
      <div
        style={{
          width: '50%',
          padding: '5px',
          display: 'inline-block',
          border: '1px solid black'
        }}
      >
        {
          booths.map((booth, i) => renderBooth(booth, i))
        }
      </div>
    </>
  );
};

export default function BoothOrderContainer({ hallId }) {
  const [booths, setBooth] = useState(null)


  useEffect(fetchBooths(hallId, setBooth), [])

  return (
    <DndProvider backend={HTML5Backend}>
      { booths && <BoothOrder booths={booths} onMmove/> }
    </DndProvider>
  )
}

// TODO: maybe preload booths from backend and frontend get from data attribute
const fetchBooths = (hallId, callback) => {
  // const hallId = getHallId()

  return () => {
    fetch(`/admin/exhibition_halls/${hallId}/booths`)
      .then(resp => resp.json())
      .then(json => callback(json))
  }
}

const getHallId = () => {
  const hallId = document.getElementById('booth-order').dataset.id
  if (!hallId) console.log('No hall id found')

  return hallId
}

import '@activeadmin/activeadmin'
import '@fortawesome/fontawesome-free/css/all.css'
import "activeadmin_reorderable"
import 'activeadmin_addons'
import { ModalDialog } from '@activeadmin/activeadmin'

import 'arctic_admin'
import 'bootstrap'
import '../stylesheets/active_admin'

var componentRequireContext = require.context('components/admin', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

const patchSidebarToggles = () => {
  // Might not be necessary anymore, removed this and KIV
  // const nestedMenuItems = document.querySelectorAll('#tabs .has_nested')
  // nestedMenuItems.forEach((nestedMenuItem) => {
  //   nestedMenuItem.addEventListener('click', (e) => {
  //     e.stopPropagation()
  //     nestedMenuItem.classList.toggle('is-open')
  //   })
  // })
}

document.addEventListener('DOMContentLoaded', () => {
  patchSidebarToggles()
})
